import { NetworkErrorType } from '@/types/enum'

export const getNetworkErrorFromStoreActionType = (
  type: string
): NetworkErrorType | undefined => {
  switch (true) {
    case ['account/fetchTransactions'].includes(type):
      return NetworkErrorType.AccountPageTransactions
    case ['bank/fetchBalances'].includes(type):
      return NetworkErrorType.AccountPageBankBalances
    case ['account/fetchCW20BalancesNoThrow'].includes(type):
      return NetworkErrorType.AccountPageCW20Balances
    case [
      'account/fetchSubaccounts',
      'account/fetchAllSubaccountBalancesWithPrices'
    ].includes(type):
      return NetworkErrorType.AccountPageSubaccountBalances
    case ['block/fetchBlock'].includes(type):
      return NetworkErrorType.BlockDetailsPage
    case ['contract/fetchContract'].includes(type):
      return NetworkErrorType.ContractDetailsPage
    case [
      'contract/fetchCw20AccountBalances',
      'contract/fetchCW20AccountBalancesTotalCount'
    ].includes(type):
      return NetworkErrorType.ContractsPageAccountsBalance
    case [
      'contract/fetchContracts',
      'contract/fetchContractsTotalCount'
    ].includes(type):
      return NetworkErrorType.ContractsPageContracts
    case ['contract/fetchContractTransactions'].includes(type):
      return NetworkErrorType.ContractPageTransactions
    case ['network/fetchBlocks', 'network/fetchBlocksTotalCount'].includes(
      type
    ):
      return NetworkErrorType.BlocksPageBlocks
    case [
      'network/fetchTransactions',
      'network/fetchTransactionsTotalCount'
    ].includes(type):
      return NetworkErrorType.TransactionsPageTransactions
    case ['transaction/fetchTransaction'].includes(type):
      return NetworkErrorType.TransactionDetailsPage
    case ['wasmCode/fetchCode'].includes(type):
      return NetworkErrorType.WasmCodeDetailsPage
    case ['wasmCode/fetchCodes', 'wasmCode/fetchCodeTotalCount'].includes(type):
      return NetworkErrorType.WasmCodePageCodes
    case ['spot/fetchTrades', 'derivative/fetchTrades'].includes(type):
      return NetworkErrorType.TradeHistory
    case ['spendPool/fetchCommunitySpendPool'].includes(type):
      return NetworkErrorType.CommunitySpendPool
    case ['account/fetchDenomHolders'].includes(type):
      return NetworkErrorType.DenomHolders
    default:
  }
}
