<script lang="ts" setup>
useHead({
  bodyAttrs: {
    class: 'font-sans text-black bg-primary-200 text-base'
  }
})
</script>

<template>
  <NuxtLayout>
    <NuxtPage
      :transition="{
        name: 'fade',
        mode: 'out-in'
      }"
    />
  </NuxtLayout>
</template>
