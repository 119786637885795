import { TradeHistorySubPage } from '@/types'

export default {
  tradeHistory: {
    buy: 'Buy',
    fee: 'Fee',
    time: 'Time',
    type: 'Type',
    side: 'Side',
    sell: 'Sell',
    event: 'Event',
    filter: 'Filter',
    payout: 'Payout',
    market: 'Market',
    amount: 'Amount',
    address: 'Address',
    tradeId: 'Trade ID',
    timestamp: 'Timestamp',
    spotTrade: 'Spot Trade',
    timeRange: 'Time range',
    allMarkets: 'All markets',
    blockHeight: 'Block height',
    tradeHistory: 'Trade history',
    tradeIdDetails: 'Trade ID details',
    tradingActivity: 'Trading Activity',
    tradeHistoryDaily: 'Trade Activity (24h)',
    clearFilters: 'Clear filters',
    feeRecipient: 'Fee recipient',
    subaccountId: 'Subaccount ID',
    derivativeTrade: 'Derivative Trade',
    filterByAddress: 'Filter by address',
    tradeHistoryDetails: 'Trade history details',

    toast: {
      notFound: `Failed to fetch trade with id {id}`
    },

    tabs: {
      [TradeHistorySubPage.SpotPage]: 'Spot',
      [TradeHistorySubPage.DerivativesPage]: 'Derivatives'
    }
  }
}
