import { defineStore } from 'pinia'
import { indexerRestExplorerApi } from '@shared/Service'
import { HttpRequestException } from '@injectivelabs/exceptions'
import { Block } from '@injectivelabs/sdk-ts'
import { BlockNotFoundException } from '@/app/exceptions/BlockNotFoundException'

type BlockStoreState = {
  blocks: Block[]
  blocksTotalCount: number
  block?: Block
}

const initialStateFactory = () => ({
  blocks: [],
  block: undefined,
  blocksTotalCount: 0
})

const initialState = initialStateFactory()

export const state = () => ({
  block: initialState.block as Block | undefined
})

export const useBlockStore = defineStore('block', {
  state: (): BlockStoreState => initialStateFactory(),
  actions: {
    async fetchBlock(blockHashHeight: string) {
      const blockStore = useBlockStore()
      const appStore = useAppStore()

      try {
        const block = await indexerRestExplorerApi.fetchBlock(blockHashHeight)

        blockStore.$patch({
          block
        })
      } catch (e: any) {
        if (e instanceof HttpRequestException) {
          if (e.code === 404 || e.message.includes('object not found')) {
            appStore.$patch({
              searchParam: blockHashHeight
            })

            throw new BlockNotFoundException()
          }
        }
        throw e
      }
    },

    async fetchBlocks({
      before,
      limit,
      updateTotalCount
    }: {
      before?: number
      limit?: number
      updateTotalCount?: boolean
    }) {
      const blockStore = useBlockStore()

      const { blocks, paging } = await indexerRestExplorerApi.fetchBlocksWithTx(
        {
          before,
          limit
        }
      )

      if (updateTotalCount) {
        blockStore.$patch({
          blocksTotalCount: paging.total
        })
      }

      blockStore.$patch({
        blocks
      })
    },

    async fetchBlocksTotalCount() {
      const blockStore = useBlockStore()

      const { paging } = await indexerRestExplorerApi.fetchBlocks({
        limit: 1
      })

      blockStore.$patch({
        blocksTotalCount: paging.total
      })
    },

    reset() {
      const blockStore = useBlockStore()

      const initialState = initialStateFactory()

      blockStore.$patch({
        ...initialState
      })
    }
  }
})
