export default {
  home: {
    stats: {
      injSupply: {
        currentSupply: 'INJ Current Supply',
        totalStaked: 'Current Total Staked',
        marketCap: 'Market Cap'
      },
      transactions: {
        transactionsCount: 'Transactions Count',
        blockHeight: 'Block Height',
        blockTime: 'Block Time'
      },
      assets: {
        tvl: 'Total Value Locked',
        numberOfAssets: 'Number of Assets',
        numberOfAddresses: 'Number of Addresses'
      },
      staked: {
        total: 'Total Staked',
        apr: 'Staking APR',
        injBurned: 'INJ Burned'
      }
    }
  }
}
