import { I18nMessageFunction } from '@/types'

export default {
  block: {
    title: 'Block details',
    hash: 'Block hash',
    height: 'Block height',
    proposer: 'Proposer',
    newBlocks: 'New blocks available',
    recentBlocks: 'Recent Blocks',
    notFoundTitle: ({ named }: I18nMessageFunction) =>
      `Sorry, We are unable to locate this block on ${named('network')}:`,
    notFoundDescription: 'Please try again.',
    invalidTitle: 'Oops! The following block format is incorrect:',
    invalidDescription: 'Please re-enter the block height correctly.',

    stats: {
      blockHeight: 'Block Height',
      blockCount: 'Block Count (Last 24h)',
      transactions: 'Transactions (Last 24h)',
      blockTime: 'Block Time'
    }
  }
}
