import { Pages, AssetSubPage, AccountSubPage } from '@/types'

export default {
  assets: {
    name: 'Name',
    type: 'Type',
    denom: 'Denom',
    price: 'Price',
    token: 'Token',
    value: 'Value',
    admin: 'Admin',
    title: 'Assets',
    native: 'Native',
    decimal: 'Decimal',
    overview: 'Overview',
    usdValue: 'USD Value',
    allAssets: 'All Assets',
    assetType: 'Asset Type',
    assetDetail: 'Asset details',
    totalAssets: 'Total Assets',
    assetsCount: 'Asset Counts',
    searchToken: 'Search Token',
    currentSupply: 'Current Supply',
    insuranceFund: 'Insurance Fund',
    smartContracts: 'Smart Contracts',
    adapterAddress: 'Adapter Address',
    creatorAddress: 'Creator Address',
    contractAddress: 'Contract Address',
    submitTokenInfo: 'Submit Token Info',
    totalAssetsValue: 'Total Assets Value',
    tokenVerification: 'Token Verification',
    assetsOnInj: 'Assets on Injective Chain',
    includeUnknownTokens: 'Include unknown tokens',
    stakedAssetsValue: 'Staked Assets Value (INJ)',

    submitTokenMetadata: 'Submit Token Metadata',
    assetsOnSmartContracts: 'Assets on Smart Contracts',
    currentSupplyTimesPrice: 'Current Supply times Price',

    adminNote: 'Minter address has the ability to increase supply',
    tokensOnInjective: 'Number of tokens circulating on Injective',
    theAmountDisplayedMayNotBeInTheCorrectScale:
      'The amount displayed may not be in the correct scale',
    theInfoOfTokenExternalVerification:
      'The name of this token is based on information found on its source network.',

    toast: {
      assetNotFound: `Asset with denom {denom} not found`
    },

    tabs: {
      [Pages.Assets]: 'Tokens',
      [AssetSubPage.SmartContractsPage]: 'Smart Contracts'
    },

    stakingTabs: {
      [AccountSubPage.StakingPage]: 'Delegations',
      [AccountSubPage.StakingUnbondingPage]: 'Unbondings',
      [AccountSubPage.StakingRedelegationsPage]: 'Redelegations'
    },

    denomHolders: {
      title: 'Holders',
      value: 'Value',
      address: 'Address',
      percentage: 'Percentage',
      quantity: 'Quantity'
    }
  }
}
